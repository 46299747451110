// src/components/Header/Header.tsx
import React from "react";
import "./Header.scss";

import classNames from 'classnames';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

export const Header = () => {
  return (
    <Navbar bg="light" expand="lg" className={classNames("pl-2","pr-4","py-0","m-0")}>
      <Navbar.Brand href="/home">
        <img
          src="/gort.svg"
          height="65"
          className="d-inline-block align-top"
          alt="Gort: Realtime Streaming Tool"
        />
      </Navbar.Brand>
      <Nav className="mr-auto">
        <Nav.Link href="https://v1.analytics-stream.glu.com/stream">Streaming Tool v1.0</Nav.Link>
      </Nav>
    </Navbar>
  );
}

export default Header;