// src/utils/fontawesome.tsx

import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faHome, faSort, faSortUp, faSortDown, faTrash, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

library.add(
  faEnvelope,
  faHome,
  faSort,
  faSortUp,
  faSortDown,
  faTrash,
  faTimesCircle
);
