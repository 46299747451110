// src/components/NotFound/NotFound.tsx
import React from "react";
import "./NotFound.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from 'classnames';

export const NotFound = () => {
  const year = new Date().getFullYear();
  return(
    <div className="NotFound">
      <div className="content-container">
        <h1 className="giant-text">404</h1>
        <h2>Well, <em>that</em> was unexpected.</h2>
        <div className="error-details">
          <p><em>Sorry, I have no clue where that page might be &hellip;</em></p>
        </div>
        <div className={classNames('error-actions', 'mt-5')}>
          <Link to='/home' className={classNames('btn', 'btn-primary', 'btn-lg', 'mr-4')}>
            <FontAwesomeIcon icon={['fas', 'home']} /> Take Me Home
          </Link>
        </div>
      </div>
      <div className="footer">
        &copy; {year}  Glu Mobile. All rights reserved.
      </div>
    </div>
  );
}