import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Checkbox, FormControlLabel } from "@material-ui/core";
import * as constants from '../utils/constants';


type EventTableHeadSelectProps = {
  headings: string[],
  show: boolean,
  onHide: any,
  onHeadingSave: (headings: string[]) => void;
}

export const EventTableHeadSelectModal = (props: EventTableHeadSelectProps) => {

  const headingsCheck: {[key: string]: boolean} = {};

  constants.EVENT_TABLE_HEADINGS.forEach((heading: string) => {
    headingsCheck[heading] = false;
  });

  props.headings.forEach((heading: string) => {
    headingsCheck[heading] = true;
  });

  const [headings, setHeadings] = useState(headingsCheck);

  const handleChange = (event: any) => {
    const { value, checked } = event.target;
    setHeadings({...headings, [value]: checked});
  }

  const onHeadingsSave = () => {
    const selectedFilters = Object.keys(headings).filter(key => headings[key]);
    window.localStorage.setItem(constants.LOCAL_STORAGE_SELECTED_FILTERS, JSON.stringify(selectedFilters));
    props.onHeadingSave(selectedFilters);
  }

  const selectAllChecked = () => {
    return Object.keys(headings).every(key => headings[key]);
  }

  const selectAll = (event: any) => {

    const { checked } = event.target;

    constants.EVENT_TABLE_HEADINGS.forEach((heading: string) => {
      headingsCheck[heading] = checked;
    });
    setHeadings({...headingsCheck})
  }

  return (
    <Modal size="lg" aria-labelledby="head-select-modal-title" onHide={props.onHide} show={props.show} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title id="head-select-modal-title">
          Choose Event Table Headings
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className={"mx-2"}>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectAllChecked()}
                onChange={selectAll}
                color="primary"
              />
            }
            label="Select All"
          />
        </Row>
        <Row className={"mx-2"}>
          {constants.EVENT_TABLE_HEADINGS.map((heading:string, index:number) => {
            return (
              <Col md={3} key={`heading-${index}`}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={headings[heading]}
                      onChange={handleChange}
                      value={heading}
                      name={`${heading}`}
                      color="primary"
                    />
                  }
                  label={`${heading}`}
                />
              </Col>
            );
          })}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.onHide}>Close</Button>
        <Button variant="outline-primary" onClick={onHeadingsSave}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}