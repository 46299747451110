import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, FormControl, InputGroup, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as constants from '../utils/constants'

import "./DeviceInputView.scss";
import classNames from "classnames";

type DeviceInputProps = {
  onDeviceSubscribe: any,
  onDeviceRemove: any,
  onUnsubscribeAll: any,
  selectDeviceIds: string[],
  getDeviceIdsInStorage: any,
  addDeviceIdToStorage: any,
};

export const DeviceInputView = (props: DeviceInputProps) => {

  const devicesHistory = props.getDeviceIdsInStorage();
  const [deviceIDs, setDeviceIDs] = useState([] as string[]);

  useEffect(() => {
    props.selectDeviceIds?.map((deviceID) => {
      if (!deviceIDs.includes(deviceID)) {
        setDeviceIDs(deviceIDs.concat(deviceID));
      }
    })
  });

  const handleSubscribe = (event: any) => {
    event.preventDefault();
    if (event.target) {
      const deviceInput = event.target.deviceId.value?.toString()?.trim().toUpperCase();
      if (deviceInput?.length <= 0) return;
      props.addDeviceIdToStorage(deviceInput);
      setDeviceIDs(deviceIDs.concat(deviceInput));
      props.onDeviceSubscribe(deviceInput);
      event.target.deviceId.value = '';
    }
  }

  const handleDeviceClick = (deviceId: string) => {
    if (!deviceIDs.includes(deviceId)) {
      props.addDeviceIdToStorage(deviceId);
      setDeviceIDs(deviceIDs.concat(deviceId));
      props.onDeviceSubscribe(deviceId);
    }
  }

  const handleTrash = (deviceId: string) => {
    let deviceIds = props.getDeviceIdsInStorage();
    deviceIds = deviceIds.filter((item: string) => item !== deviceId);
    window.localStorage.setItem(constants.LOCAL_STORAGE_DEVICE_ID_HISTORY, JSON.stringify(deviceIds));
    setDeviceIDs(deviceIDs.filter((itemId: string) => itemId !== deviceId));
    props.onDeviceRemove(deviceId);
  }

  const handleUnsubscribe = (deviceId: string) => {
    setDeviceIDs(deviceIDs.filter((itemId: string) => itemId !== deviceId));
    props.onDeviceRemove(deviceId);
  }

  const handleClear = () => {
    setDeviceIDs([]);
    props.onUnsubscribeAll(deviceIDs);
  }

  return (
    <div className="device-input">
      <Form inline onSubmit={handleSubscribe}>
        <Dropdown as={InputGroup} size="sm">
          <Dropdown.Toggle as={FormControl} autoComplete="off" placeholder="Enter Device ID" name="deviceId"/>
          <Dropdown.Menu>
            {devicesHistory.map((deviceId: string, index: number) => {
              return (
                <Dropdown.Item eventKey={deviceId} className="p-8" key={index}>
                  <Button variant="link" onClick={() => handleDeviceClick(deviceId)} size="sm" className="item">{deviceId}</Button>
                  <Button variant="link" onClick={() => handleTrash(deviceId)} size="sm" className="ctrl">
                    <FontAwesomeIcon icon={['fas', 'trash']}/>
                  </Button>
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
          <InputGroup.Append>
            <Button variant="outline-primary" type="submit">Add</Button>
            <Button variant="outline-secondary" onClick={handleClear}>Clear</Button>
          </InputGroup.Append>
        </Dropdown>
      </Form>
      <Row className="mt-4 mx-0">
        <Col className="pl-1" xs="auto">Active subscriptions:</Col>
        <Col xs="auto">
          {deviceIDs.map((deviceId: string, index: number) => {
              return (
                <span className={classNames("badge", "badge-secondary", "mr-2", "pl-2", "py-1")} key={index}>
                  {deviceId}
                  <span className={classNames("ctrl", "ml-2")} onClick={() => handleUnsubscribe(deviceId)}>
                    <FontAwesomeIcon icon={['fas', 'times-circle']} />
                  </span>
                </span>
              );
            })}
        </Col>
      </Row>
    </div>
  );
}