import React, {useCallback, useEffect, useState} from 'react';
import { EventMessage } from "../class/EventMessage";
import { connect, subscribe, unsubscribe } from '../api/socket';
import Header from "./Header";
import { DeviceInputView } from "./DeviceInputView";
import { EventTableView } from "./EventTableView";
import { useLocation, useHistory} from 'react-router-dom';
import * as constants from '../utils/constants'


export const EventStreamView = () => {

  const [allEventMessages, setAllEventMessages] = useState([] as EventMessage[]);
  const [eventMessages, setEventMessages] = useState([] as EventMessage[]);
  const [activeDeviceIds, setActiveDeviceIds] = useState([] as string[]);
  const [paramDeviceIds, setActiveParamDeviceIds] = useState([] as string[]);
  const history = useHistory();


  const query = new URLSearchParams(useLocation().search);
  const devices = query.get('s')?.split(',');

  useEffect(() => {
    connect((msg: any) => {
      const streamEvent = JSON.parse(msg.data);
      if (activeDeviceIds.includes(streamEvent.s)) {
        setEventMessages(eventMessages.concat(streamEvent));
        setAllEventMessages(allEventMessages.concat(streamEvent));
      }
    });
  }); 

  useEffect(() => {
    devices?.map((deviceID) => {
      if (!paramDeviceIds.includes(deviceID)) {
        setActiveParamDeviceIds(paramDeviceIds.concat(deviceID));
        addDeviceIdToStorage(deviceID);
        onDeviceSubscribe(deviceID);
      }
    })
  });

  const onDeviceSubscribe = (deviceId: string) => {
    setActiveDeviceIds(activeDeviceIds.concat(deviceId));
    subscribe(deviceId);
  }

  const onDeviceUnSubscribe = (deviceId: string) => {
    unsubscribe(deviceId);
    setActiveDeviceIds(activeDeviceIds.filter((activeDeviceId: string) => activeDeviceId !== deviceId));
    if(paramDeviceIds.length > 0)
    {
    setActiveParamDeviceIds(paramDeviceIds.filter((deviceID: string) => deviceID !== deviceId));
    history.push("/stream")
    }
  }

  const onUnsubscribeAll = (deviceIds: string[]) => {
    deviceIds.forEach((deviceId: string) => {
      unsubscribe(deviceId);
    });
    setEventMessages([]);
    setAllEventMessages([]);
    setActiveDeviceIds([]);
    setActiveParamDeviceIds([]);
  }

  const handleClearData = () => {
    setEventMessages([]);
    setAllEventMessages([]);
  }

  const getDeviceIdsInStorage = () => {
    const deviceIdsInText = window.localStorage.getItem(constants.LOCAL_STORAGE_DEVICE_ID_HISTORY) || '';
    return deviceIdsInText.length ? JSON.parse(deviceIdsInText) : [] as string[];
  }

  const addDeviceIdToStorage = (deviceId: string) => {
    let addedDevices = getDeviceIdsInStorage();
    if (!addedDevices.includes(deviceId)) {
      addedDevices = addedDevices.concat(deviceId);
      window.localStorage.setItem(constants.LOCAL_STORAGE_DEVICE_ID_HISTORY, JSON.stringify(addedDevices));
    }
  }

  const onSearchQueryUpdate = useCallback((query) => {

    setEventMessages(allEventMessages.filter((eventMessage:EventMessage) => {
      return Object.entries(eventMessage).some(([,value]) => {
        const lowerCasedItemValue = value.toString().toLowerCase().trim();
        return lowerCasedItemValue.includes(query);
      });
    }))
  }, [setEventMessages, allEventMessages])

  return (
    <div className="event-stream">
      <Header/>
      <DeviceInputView
        onDeviceSubscribe={onDeviceSubscribe}
        onDeviceRemove={onDeviceUnSubscribe}
        onUnsubscribeAll={onUnsubscribeAll}
        selectDeviceIds={paramDeviceIds}
        getDeviceIdsInStorage={getDeviceIdsInStorage}
        addDeviceIdToStorage={addDeviceIdToStorage}
      />
      <div className="card border-0 mt-4">
        <div className="card-body table-responsive">
          <EventTableView
            onSearchQueryUpdate={onSearchQueryUpdate}
            handleClearData={handleClearData}
            eventMessages={eventMessages}/>
        </div>
      </div>
    </div>
  );
}