import React, {useCallback, useMemo, useState} from "react";
import { EventMessage } from "../class/EventMessage";

import { Row as BootstrapRow } from "react-bootstrap"; // rename to avoid naming conflict with react table
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import MaUTable from '@material-ui/core/Table';
import {
  createStyles,
  makeStyles,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  TableHead,
  TableContainer,
  Theme,
  withStyles
} from "@material-ui/core/";
import {Cell, useSortBy, useTable, Row} from "react-table";
import ReactJson from "react-json-view";
import {epochToString} from "../utils/format";
import {EventTableHeadSelectModal} from "./EventTableHeadSelectModal";
import * as constants from "../utils/constants";

type EventHistoryViewProps = {
  handleClearData: any,
  onSearchQueryUpdate: any,
  eventMessages: any[] // Have to use type <any> to be compatible with react-table Column Type
}

const useTableStyles = makeStyles({
  container: {
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
   },
  header: {
    minWidth: "138px",
  },
});

export const EventTableView = (props: EventHistoryViewProps) => {

  const selectedHeadings = window.localStorage.getItem(constants.LOCAL_STORAGE_SELECTED_FILTERS);
  const selected = (selectedHeadings != null ? JSON.parse(selectedHeadings) : constants.EVENT_TABLE_HEADINGS) as string[];
  const [headings, setHeadings] = useState(selected);
  const [headingModalDisplay, setHeadingModalDisplay] = useState(false);

  const tableClasses = useTableStyles();

  const onSearchQueryChange = (event: any) => {
    const filter = event.target.value;

    const lowerCasedValue = filter.toLowerCase().trim();
    props.onSearchQueryUpdate(lowerCasedValue);
  }

  const onExportTSVClick = () => {
    let tsvContent = "data:text/tsv;charset=utf-8,";

    tsvContent += headings.join('\t');
    tsvContent += '\r\n';

    tsvContent += props.eventMessages.map((eventMessage: any) => {
      const dataField: any = [];
      headings.forEach(heading => {
        dataField.push(JSON.stringify(eventMessage[heading]));
      });
      return dataField.join('\t');
    }).join('\r\n');

    generateDownloadWindow('export.tsv', tsvContent);
  }

  const onExportJSONClick = () => {
    const jsonContent = `data:application/json;charset=utf-8, ${JSON.stringify(props.eventMessages, null, 2)}`;
    generateDownloadWindow('data.json', jsonContent);
  }

  const generateDownloadWindow = (fileName: string, content: string) => {
    const encodedUri = encodeURI(content);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const onHeadingSave = useCallback((headings: string[]) => {
    setHeadings(headings);
    setHeadingModalDisplay(false);
  }, [setHeadings, setHeadingModalDisplay]);

  const columns = useMemo(() => {
    return headings.map((heading: string) => {
      const entry = Object.assign({}, {
        Header: heading,
        accessor: heading as keyof EventMessage,
      });

      switch (heading) {
        case 'json_data':
          return Object.assign(entry, {
            Cell: ({cell: {value}}: any) => <ReactJson src={value} name={false} quotesOnKeys={false} displayDataTypes={false} collapsed={true}/>
          });
        case 'ts':
          return Object.assign(entry, {
            Cell: ({cell: {value}}: any) => epochToString(value)
          });
        default:
          return entry;
      }
    })
  }, [headings]);

  const messages = React.useMemo(() => props.eventMessages, [props.eventMessages]);

  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.common.white,
      },
      body: {
        fontSize: 14,
      },
    }),
  )(TableCell);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns: columns,
      data: messages,
    },
    useSortBy,
  );

  return (
    <div className="event-history">
      <BootstrapRow className="mx-0">
        <Col md={4} className="my-2 pl-0">
          <FormControl size="sm" autoComplete="off" name="search" placeholder="Search" onChange={onSearchQueryChange}/>
        </Col>
        <Col md={8} className="my-2">
          <BootstrapRow className="justify-content-end">
            <Button className="mx-2" variant="outline-primary" size="sm" onClick={() => setHeadingModalDisplay(true)}>Choose Columns</Button>
            <Button className="mx-2" variant="outline-primary" size="sm" onClick={onExportTSVClick}>Export to TSV</Button>
            <Button className="mx-2" variant="outline-primary" size="sm" onClick={onExportJSONClick}>Export to JSON</Button>
            <Button className="mx-2" variant="outline-primary" size="sm" onClick={props.handleClearData}>Clear Data</Button>
          </BootstrapRow>
        </Col>
      </BootstrapRow>

      <TableContainer className={tableClasses.container}>
        <MaUTable {...getTableProps()}>
          <TableHead>
            { headerGroups.map(headerGroup => (
              <TableRow { ...headerGroup.getHeaderGroupProps()}>
                { headerGroup.headers.map(column => (
                  <StyledTableCell className={tableClasses.header}
                                   {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <TableSortLabel
                      active={column.isSorted}
                      direction={column.isSortedDesc ? "desc" : "asc"}/>
                  </StyledTableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row: Row<any>, index:number) => {
              // prepare rows and get the row props from react-table dynamically
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()} style={ index % 2 ? { background : "aliceblue" }:{ background : "white" } }>
                  {row.cells.map((cell:Cell<EventMessage>) => {
                    return <TableCell {...cell.getCellProps()}>{cell.render("Cell")}</TableCell>
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MaUTable>
      </TableContainer>
      <EventTableHeadSelectModal
        headings={headings}
        show={headingModalDisplay}
        onHeadingSave={onHeadingSave}
        onHide={() => setHeadingModalDisplay(false)}
      />
    </div>
  );
}