// App.tsx
import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import './App.css';
import { EventStreamView } from "./components/EventStreamView";
import { NotFound } from "./components/NotFound";

class App extends Component {

  render() {
    return (
      <Router>
        <div className="App">
          <CssBaseline />
          <Switch>
            <Route path="/stream" component={EventStreamView}/>
            <Route path="/home" component={EventStreamView}/>
            <Route exact path="/" component={EventStreamView}/>
            <Route component={NotFound}/>
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;