// src/utils/format.tsx

import { DateTime } from "luxon";

export const epochToString = (v: number) => {
  let stringValue = v ? v.toString() : '';
  if (v !== parseInt(stringValue, 10)) {
    return stringValue;
  }
  switch (stringValue.length) {
    case 10:
      stringValue = DateTime.fromSeconds(v).toLocaleString(DateTime.DATETIME_FULL);
      break;
    case 13:
      stringValue = DateTime.fromMillis(v).toLocaleString(DateTime.DATETIME_FULL);
      break;
    default:
  }
  return stringValue;
};
