export const EVENT_TABLE_HEADINGS = [
  's',
  'uid',
  'client_ip',
  'revid',
  'sess_id',
  'game_name',
  'event_type',
  'st1',
  'st2',
  'st3',
  'n',
  'l',
  'v',
  'ts',
  'c',
  'tu',
  'json_data',
  'kt_v',
  'time_stamp',
  'v_maj',
  'v_min',
  'v_rev',
  'os',
  'app_key',
  'req_id',
  'i',
];

export const LOCAL_STORAGE_SELECTED_FILTERS = 'selected-filters';
export const LOCAL_STORAGE_DEVICE_ID_HISTORY = 'device_id_history';

export const LOCAL_SOCKET_URL = 'ws://localhost:8080/socket';