// api/socket.tsx
import { LOCAL_SOCKET_URL } from "../utils/constants";
import { v4 as uuid } from 'uuid';

const server = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_SOCKET_SERVER_URL || LOCAL_SOCKET_URL : LOCAL_SOCKET_URL;
const username = uuid().split('-')[0];
const url = `${server}?username=${username}`;
const socket = new WebSocket(url);


const waitForOpenConnection = (socket: any) => {
  return new Promise<void>((resolve, reject) => {
      const maxNumberOfAttempts = 10
      const intervalTime = 200 //ms

      let currentAttempt = 0
      const interval = setInterval(() => {
          if (currentAttempt > maxNumberOfAttempts - 1) {
              clearInterval(interval)
              reject(new Error('Maximum number of attempts exceeded'))
          } else if (socket.readyState === socket.OPEN) {
              clearInterval(interval)
              resolve()
          }
          currentAttempt++
      }, intervalTime)
  })
}

let connect = (cb: any) => {

  socket.onopen = () => {
    console.log(`Successful Connection: ${url}`);
  };

  socket.onmessage = (msg: any) => {
    cb(msg);
  };

  socket.onclose = (event: any) => {
    console.log("Socket closed connection: ", event);
  };

  socket.onerror = (error: any) => {
    console.log("Socket error: ", error);
  };
};

let subscribe = async (deviceID: string) => {
  const clientMessage = {
    "command": 0,
    "channel": deviceID
  };
  console.log("Subscribing to channel: ", deviceID);

  if (socket.readyState !== socket.OPEN) {
    try {
        await waitForOpenConnection(socket)
        socket.send(JSON.stringify(clientMessage));
      } catch (err) { console.error(err) }
} else {
    socket.send(JSON.stringify(clientMessage));
}
};

let unsubscribe = (deviceID: string) => {
  const clientMessage = {
    "command": 1,
    "channel": deviceID
  };
  console.log("Unsubscribing from channel: ", deviceID);
  socket.send(JSON.stringify(clientMessage));
};

let sendMsg = (msg: any) => {
  const blob = {
    "event": "sub",
    "payload": "some text here"
  };
  console.log("sending msg: ", msg);
  socket.send(msg);
  socket.send(JSON.stringify(blob));
};

export { connect, sendMsg, subscribe, unsubscribe };
